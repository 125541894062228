<template>
  <div>
    <!--顶部-->
    <div class="banner-content clearfix">
      <!--说明区域-->
      <div class="explain-content">
        <img src="../assets/yqlogo.png" class="logo" />
        <div class="explain-text">
          <div class="text-one">请打开智慧课堂客户端进行备授课</div>
          <div class="text-two">
            此应用非网页应用，使用请点击右侧按钮启动或下载客户端
          </div>
          <div class="text-three">启动前请确保本地已安装客户端</div>
          <div class="text-four">支持系统：win7 / win8 / win10</div>
        </div>
      </div>
    </div>
    <div class="yq-content">
      <div class="left-ny">
        <div class="content-title">
          <span>轻松备课</span>
          <span>高效授课</span>
          <span>助力教学更精彩</span>
        </div>
        <div class="btn-area">
          <div class="yq-teach-btn" @click="teachClass">立即授课</div>
          <a
            href="https://bocsmartclass.17zuoye.cn/downloadopen/智慧课堂授课端.exe"
          >
            <div class="download-btn">
              <div class="title">
                <img
                  src="../assets/yq_icon_download.png"
                  class="download-icon"
                />
                <span>下载授课端</span>
              </div>
              <div class="explain">在教室安装使用</div>
            </div>
          </a>
        </div>
      </div>
      <img src="https://fs.iclass30.com/aliba/resource/region/gzjh/pic-2.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getUserByYqTicketAPI } from "@/service/api.js";

export default {
  name: "index",
  computed: {
    ...mapGetters(["screenDownLoadUrl"]),
  },
  data() {
    return {
      loading: true,
      userId: "",
      token: "",
      userType: "",
      showDownloadModal: false,
      clientUrl: "http://127.0.0.1:8069/qry?param=c30dp",
      clientUrlNew: "http://127.0.0.1:10620/qry?param=c30dp", // 原旧端口8069会导致大屏崩溃
      //按钮是否可点击
      isClickBtn: false,
    };
  },
  methods: {
    /**
     * 一起
     */
    async yqUserLogin() {
      let _this = this;
      // console.log('ticket:'+_this.getQueryString('ticket',window.location.hrefg))
      if (_this.$route.query.ticket) {
        const res = await getUserByYqTicketAPI({
          ticket: _this.$route.query.ticket,
        });
        if (res && parseInt(res.code) === 1) {
          console.log('userId:'+res.data.id)
          _this.userId = res.data.id;
          _this.token = res.data.token;
          _this.userType = res.data.user_type;
          //按钮是否可以点击，请求完用户信息才可以点击
          _this.isClickBtn = true;
        }
      } else {
      }
    },
    /**
 * @name 获取请求接口参数
 */
getQueryString(name, url) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = url.substring(url.indexOf('?'), url.length).substr(1).match(reg);
      if (r != null) return decodeURI(r[2]);
      return null;
},
    /**s
     * 启动大屏 使用新端口
     * 思路：先使用新端口启动大屏，如果新端口启动失败再启动8069老端口
     */
    startNewScreenProgram() {
      let _this = this;
      $.ajax({
        url: this.clientUrlNew,
        type: "POST",
        dataType: "json",
        timeout: 10000,
        success: function (res) {
          // 启动大屏成功
          _this.loading = false;
          // 关闭当前窗口
          _this.closeWin();
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          //新端口启动失败
          _this.startScreenProgram();
        },
      });
    },
    /**
     * 启动大屏
     */
    startScreenProgram() {
      let _this = this;
      $.ajax({
        url: this.clientUrl,
        type: "POST",
        dataType: "json",
        timeout: 10000,
        success: function (res) {
          // 启动大屏成功
          _this.loading = false;
          // 关闭当前窗口
          _this.closeWin();
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          //大屏启动失败
          if (XMLHttpRequest.readyState === 0) {
            // _this.startFailCallback();
            _this.showDownloadModal = true;
            _this.loading = false;
          }
        },
      });
    },
    /**
     * 启动大屏失败回调事件
     */
    startFailCallback(params, type) {
      let _this = this;
      location.href = "zndp://uid=" + this.userId + "&token=" + this.token;
      console.log("zndp://uid=" + this.userId + "&token=" + this.token);
      setTimeout(function () {
        _this.startNewScreenProgram();
      }, 5000);
    },
    /**
     * 立即授课
     */
    teachClass() {
      if (this.isClickBtn) {
        if (this.userType == "1") {
          location.href = "zndp://uid=" + this.userId + "&token=" + this.token;
        } else {
          alert("该应用为教师应用，请登录教师账号获取更多服务");
        }
      }
    },
    /**
     * 检查客户端是否存在
     */
    checkProgram: function () {
      let _this = this;
      // _this.enterHandler && clearTimeout(self.enterHandler);
      // _this.enterHandler = setTimeout(function () {
      $.ajax({
        url: _this.clientUrl,
        type: "GET",
        timeout: 10000,
        success: function (response) {
          // 启动大屏成功
          console.log("大屏客户端存在");
          _this.loading = false;
          // 关闭当前窗口
          _this.closeWin();
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          if (XMLHttpRequest.readyState === 0) {
            //启动大屏失败
            console.log("大屏客户端不存在");
            // _this.loading = false;
            // _this.showDownloadModal = true;
            _this.startFailCallback();
            // _this.checkProgramNew();
          }
        },
      });
      // }, 8000);
    },
    /**
     * 检查客户端是否存在
     */
    checkProgramNew: function () {
      let _this = this;
      $.ajax({
        url: _this.clientUrlNew,
        type: "GET",
        timeout: 10000,
        success: function (response) {
          // 启动大屏成功
          console.log("大屏客户端存在");
          _this.loading = false;
          // 关闭当前窗口
          _this.closeWin();
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          if (XMLHttpRequest.readyState === 0) {
            //启动大屏失败
            console.log("大屏客户端不存在");
            // _this.loading = false;
            // _this.showDownloadModal = true;
            _this.startFailCallback();
          }
        },
      });
    },
    /**
     * 关闭当前窗口
     */
    closeWin() {
      this.showDownloadModal = false;
      window.opener = null; // 切断引用 ie低版本的需要赋值为null
      window.open("", "_self"); // 重置opener为当前页面自身
      window.close(); // 此时关闭不会提示
    },
  },
  mounted() {
    this.yqUserLogin();
  },
};
</script>

<style lang="scss" scoped>
@import "../style/screenMain";
.text-three {
  color: #1865ff !important;
}
.btn-area {
  display: flex;
  align-items: center;
}
a {
  text-decoration: none;
}
</style>
